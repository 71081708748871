//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import PartnerFooter from '@/components/common/footer/PartnerFooter'
import Preloader from '@/components/common/Preloader'
import GdprCookieModal from '@/modules/gdpr/components/GdprCookieModal'
import EkbModal from '@/modules/ekbModal/components/EkbModal'
import CityBillet from '~/components/modals/CityBillet'

export default {
  name: 'PartnerLayout',
  components: {
    PartnerFooter,
    GdprCookieModal,
    EkbModal,
    CityBillet,
    Preloader,
  },
  computed: mapGetters({
    gdprSaved: 'gdpr/gdprSaved',
  }),
  methods: {
    isRussianFranchise() {
      return this.$getTextMainSetting('isRussianFranchise')
    },
  },
}
